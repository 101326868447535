/* global ga */
import Plyr from "plyr";
$().ready(function(){
	setupSlideMenu();
	if ($("form").length){
		$("form").each(function(){
			$(this).addClass("floatlabel-active").validate();
			$(this).find(".js-float-wrap").FloatLabel();
		});
	}
	if ($(".banners .banner").length > 1){
		scrollBanners();
	}
	/* resources display-mode switch */
	$("a.mode").on("click", function(){
		if(!$(this).hasClass("active")){ // new mode selected
			$("a.mode").removeClass("active");
			var mode = $(this).attr("id");
			var lastClass = $("#list > div").attr("class").split(" ").pop();
			if(lastClass != "list"){ /* clear mode */
				$("#list > div").removeClass(lastClass);
			}
			$("#list > div").addClass(mode);
			$(this).addClass("active");
		}
	});
	$(document).on("click","a",function(){
		if (ga) {
			var a = this;
			// var $a = $(this);
			if (a.href.indexOf(location.host) == -1 && a.href.match(/^https?:\/\//i)){
				captureOutboundLink(a.href);
				return false;
			}
		}
	});
});
$(window).on("resize", function(){
	setTimeout(function(){
		//realign banners - to start of active banner
		$(".banners").each(function(){
			var $scroller = $(this).find(".banner-stage");
			if ($scroller.scrollLeft() != 0){
				var pos = $scroller.scrollLeft() + $scroller.find(".banner.active").position().left;
				$scroller.scrollLeft(pos);
			}
		});
	}, 200);
});

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}
function slide(offset, $bnav, $scroller){
	var $distance = $scroller.innerWidth();
	//var $before = $scroller.find(".banner.active").prev();
	var $after = $scroller.find(".banner.active").next();

	if (!$scroller.data("animating")){
		$scroller.data("animating", true);
		$scroller.players.forEach((player) => {
			player.pause();
		});
		var newPos;
		if (offset < 0) { //fwd next
			if ($after.length>0){
				newPos = $scroller.scrollLeft() + $distance;
				$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
					$scroller.data("animating", false);
				});
				// set active
				$scroller.find(".banner.active").removeClass("active");
				$after.addClass("active");
				var i= $after.index();
				$bnav.removeClass("active");
				$bnav.eq(i).addClass("active");
			} else { /* you're at the end - go to first */
				var startPos = $scroller.scrollLeft(0);
				$scroller.stop().animate({scrollLeft:startPos},500,"swing", function(){
					$scroller.data("animating", false);
				});
				// set active
				$scroller.find(".banner.active").removeClass("active");
				$scroller.find(".banner").first().addClass("active");
				$bnav.removeClass("active");
				$bnav.eq(0).addClass("active");
			}
		} else { //button index
			var $index = offset;
			var $new = $scroller.find(".banner").eq($index);
			newPos = $scroller.scrollLeft() + $new.position().left;
			$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
				$scroller.data("animating", false);
			});
			pauseBanner();
			/* set active */
			$bnav.removeClass("active");
			$bnav.eq($index).addClass("active");
			$scroller.find(".banner.active").removeClass("active");
			$new.addClass("active");
			playBanner($bnav, $scroller);
		}
	}
}
function playBanner($bnav, $scroller){
	clearInterval(rotInt);
	rotInt = setInterval(function(){slide(-1, $bnav, $scroller);}, 5000);
}
function pauseBanner(){
	clearInterval(rotInt);
}
var rotInt;
export function scrollBanners(){
	$(".banners").each(function(){
		var $banner = $(this);
		var $scroller = $(this).find(".banner-stage");
		var $bnav = $(this).find(".banner-nav-item");
		if ($(this).find(".banner").length > 1){
			$(this).find(".banner-navigation").show();
			/* auto rotate */
			playBanner($bnav, $scroller);
			/* clicks */
			$bnav.each(function(index, element){
				var $button = $(element);
				$button.on("click touch", function(){
					var index = $(this).index();
					slide(index, $bnav, $scroller);
				});
			});
			$banner.on("mouseover", function(){
				pauseBanner();
			});
			$banner.on("mouseout", function(){
				if (!$scroller.players.some((player) => !player.paused)){
					playBanner($bnav, $scroller);
				}
			});
		}
		let youtube_banners = Array.from($scroller.get(0).querySelectorAll(".banner-youtube"));
		let players = youtube_banners.map((el) => {
			let video_id = el.dataset.youtubeId;
			el.insertAdjacentHTML("beforeend", "<div class=\"youtube-player\" data-plyr-provider=\"youtube\" data-plyr-embed-id=\"" + video_id + "\"></div>");
			let p = el.querySelector(".youtube-player");
			let player = new Plyr(p, {
				hideControls:true,
				controls:["play-large"],
				ratio: "21:9"
			});
			player.on("playing", () =>{pauseBanner();});
			player.on("pause", () =>{playBanner($bnav, $scroller);});
			return player;
		});
		$scroller.players = players;
	});
	
}

var scrollTimer = null;
$(window).on("scroll", function(){
	$("header").removeClass("grow");
	clearTimeout(scrollTimer);
	var pos = $(window).scrollTop();
	(pos > 0)? $("header").addClass("sticky") : $("header").removeClass("sticky");
	scrollTimer = setTimeout(function(){$("header").addClass("grow");}, 250);
});
export function notMobile(){
	const mobile = window.matchMedia("(max-width: 960px)");
	var not_mobile = (mobile.matches)? false : true;
	return not_mobile;
}


var captureOutboundLink = function(url) {
	var eventHit;
	ga("send", "event", "outbound", "click", url, {
		"transport": "beacon",
		"hitCallback": function(){
			eventHit = true;
			document.location = url;
		}
	});
	setTimeout(function () {
		if (!eventHit) {
			document.location = url;
		}
	}, 1000);
};
